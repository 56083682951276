import { getUnpaidContractsCount, getContractsValue } from '@/store/mainInterface';
import { computed } from 'vue';
import router from '@/router';

type Menu = {
  name: string;
  items: {
    name: string;
    route?: string;
    count?: number;
    active?: boolean;
    href?: string;
  }[];
};


export default function synMenuItems() {

  const synvestMenu = computed(() => {
    const currentRoutePath = router?.currentRoute.value.path;
    const unpaidCount = computed(() => getUnpaidContractsCount());
    const hasContractsValue = computed(() => getContractsValue());

    const items: Menu[] = [
      {
        name: 'Mijn overzicht',
        items: [
          {
            name: 'Mijn vastgoed',
            route: '/mijn-vastgoed',
          },
          {
            name: 'Betaling afronden',
            route: '/mijn-betaling-afronden',
            count: unpaidCount.value,
          },
        ]
      },
      {
        name: 'Producten toevoegen',
        items: [
          {
            name: 'Nederlands vastgoed',
            route: '/nederlands-vastgoed',
          },
          {
            name: 'Duits vastgoed',
            route: '/duits-vastgoed',
          },
        ]
      }
    ];

    if (hasContractsValue.value) {
      items.push({
        name: 'Jaarverslagen',
        items: [
          {
            name: 'Synvest vastgoed',
            href: 'https://synvest.nl/jaarrekeningenvastgoedfondsen',
          }
        ]
      }
      )
    }
    items.forEach(menu => {
      menu.items.forEach(item => {
        item.active = currentRoutePath?.startsWith(item.route as string);
      });
    });
    return items;
  });

  return { synvestMenu };
}
