import store, { StoreState } from "@/store";
import type { GetterTree, MutationTree } from "vuex";
import type { RiskProfileType } from "@/types";
import {
  AanvragerType,
  Answer,
  DutchBoolean,
  InvestmentFrequency,
  Question,
  Questionnaire,
  RiskSelectionType,
  StatementType,
  ThirdStatementType,
} from "./flowInterface";
import { SynvestSteps, SynvestSubSteps } from "@/types/synvestCommon";
import { KopieType } from "./aanvragerInterface";
import { showLoading } from "./mainInterface";
import request, { requestMethodType } from "@/plugins/request";

/*
*************** 
    TYPES
***************
*/

export type UploadValueType = { name: string; content: string } | null;
type Payment = { paymentId: any; requestId: any };
type Twikey = { key: string; mandateId: string; url: string };
export type FlowName = "duits" | "nederlands";

export type SynvestInvestementAmount = {
  initialDeposit: string | null;
  periodicDeposit: string | null;
  description: string | null;
  kind: InvestmentFrequency | null;
  stock: "stock" | "cash";
};

type QuestionnaireData = {
  activeQuestion: number;
  answers: Answer[] | null;
  firstStatement: StatementType | null;
  questionEleven: DutchBoolean | null;
  questions: Question[];
  riskProfiles: RiskProfileType[];
  riskSelection: RiskSelectionType | null;
  score: number | null;
  secondStatement: StatementType | null;
  thirdStatement: ThirdStatementType | null;
};

type PersonTaxInfo = {
  uitsluitend: string;
  bsn: string;
  identiteitsbewijs: string;
  identiteitsbewijsCard: string;
  geldigTot: string | null;
  kopie: KopieType | null;
  voorkant: UploadValueType;
  achterkant: UploadValueType;
};

type ContactPerson = {
  geslacht: string;
  initialen: string;
  tussenvoegsel: string;
  achternaam: string;
  geboortedatum: string | null;
  mobiel: string;
  telefoonnummer: string;
  iban: string;
  land: string;
  postcode: string;
  huisnummer: string;
  straatnaam: string;
  woonplaats: string;
  personTaxInfo: PersonTaxInfo | null;
};

type CompanyInfo = {
  naamBedrijf: string;
  kvk: string;
  rsin: string;
  kvkKopie: string;
  kvkUploaden: UploadValueType;
  UBOKopie: string;
  UBOUploaden: UploadValueType;
  telefoonnummer: string;
  iban: string;
  land: string;
  postcode: string;
  huisnummer: string;
  straatnaam: string;
  woonplaats: string;
  contactPerson?: ContactPerson;
};

export type SynvestFlowState = {
  synvestFlow: {
    currentFlow: FlowName | null;
    activePage: SynvestSteps;
    activeSubstep: SynvestSubSteps | null;
    hasInvestment: boolean;
    navigateToPayment: boolean;
    synvestInvestementAmount: SynvestInvestementAmount;
    requestType: AanvragerType | null;
    payment: Payment | null;
    questionnaire: QuestionnaireData | null;
    companyInfo: CompanyInfo;
    twikey: Twikey | null;
  };
};

/*
*************** 
    STATE
***************
*/

const questionnaireInitialState: QuestionnaireData = {
  activeQuestion: 0,
  answers: null,
  firstStatement: null,
  questionEleven: null,
  questions: [],
  riskProfiles: [],
  riskSelection: null,
  score: null,
  secondStatement: null,
  thirdStatement: null,
};

export const synvestFlowState: SynvestFlowState = {
  synvestFlow: {
    currentFlow: null,
    activePage: SynvestSteps.Investement,
    activeSubstep: null,
    hasInvestment: false,
    navigateToPayment: false,
    synvestInvestementAmount: {
      initialDeposit: null,
      periodicDeposit: null,
      description: null,
      kind: null,
      stock: "stock",
    },
    requestType: null,
    payment: null,
    questionnaire: {
      ...questionnaireInitialState,
    },
    companyInfo: {
      naamBedrijf: "",
      kvk: "",
      rsin: "",
      kvkKopie: "",
      kvkUploaden: null,
      UBOKopie: "",
      UBOUploaden: null,
      telefoonnummer: "",
      iban: "",
      land: "",
      postcode: "",
      huisnummer: "",
      straatnaam: "",
      woonplaats: "",
      contactPerson: {
        geslacht: "",
        initialen: "",
        tussenvoegsel: "",
        achternaam: "",
        geboortedatum: null,
        mobiel: "",
        telefoonnummer: "",
        iban: "",
        land: "",
        postcode: "",
        huisnummer: "",
        straatnaam: "",
        woonplaats: "",
        personTaxInfo: {
          uitsluitend: "",
          bsn: "",
          identiteitsbewijs: "",
          identiteitsbewijsCard: "",
          geldigTot: null,
          kopie: null,
          voorkant: null,
          achterkant: null,
        },
      },
    },
    twikey: null,
  },
};

/*
*************** 
    MUTATIONS
***************
*/

export const synvestInterfaceMutations: MutationTree<StoreState> = {
  resetSynvestFlow: (state): void => {
    state.synvestFlow = {
      ...synvestFlowState.synvestFlow,
      questionnaire: {
        ...questionnaireInitialState,
      },
    };
  },
  setSynvestFlowName: (state, payload: FlowName): void => {
    state.synvestFlow.currentFlow = payload;
  },
  setSynvestActivePage: (state, payload: SynvestSteps): void => {
    state.synvestFlow.activePage = payload;
  },
  setSynvestActiveSubstep: (state, payload: SynvestSubSteps): void => {
    state.synvestFlow.activeSubstep = payload;
  },
  setSynvestHasInvestment: (state, payload: boolean): void => {
    state.synvestFlow.hasInvestment = payload;
  },
  setSynvestRequestType: (state, payload: AanvragerType): void => {
    state.synvestFlow.requestType = payload;
  },
  setInvestementAmount: (state, payload: SynvestInvestementAmount): void => {
    state.synvestFlow.synvestInvestementAmount = payload;
  },
  setCompanyInfo: (state, payload: CompanyInfo): void => {
    state.synvestFlow.companyInfo = {
      ...state.synvestFlow.companyInfo,
      ...payload,
    };
  },
  setCompanyContactPerson: (state, payload: ContactPerson): void => {
    state.synvestFlow.companyInfo.contactPerson = {
      ...state.synvestFlow.companyInfo.contactPerson,
      ...payload,
    };
  },
  setCompanyContactPersonTax: (state, payload: PersonTaxInfo): void => {
    if (state.synvestFlow.companyInfo.contactPerson) {
      state.synvestFlow.companyInfo.contactPerson.personTaxInfo = {
        ...state.synvestFlow.companyInfo.contactPerson.personTaxInfo,
        ...payload,
      };
    }
  },
  setNavigateToPayment: (state, payload: boolean): void => {
    state.synvestFlow.navigateToPayment = payload;
  },
  setQuestionnaireScore: (state, payload: { score: number }): void => {
    if (state.synvestFlow.questionnaire)
      state.synvestFlow.questionnaire.score = payload.score;
  },
  setQuestionnaireAnswers: (state, payload: Answer[]) => {
    if (state.synvestFlow.questionnaire)
      state.synvestFlow.questionnaire.answers = payload;
  },
  setSynvestQuestionnaireQuestions: (state, payload: Question[]) => {
    if (state.synvestFlow.questionnaire)
      state.synvestFlow.questionnaire.questions = payload;
  },
};

export const resetSynvestFlow = (): void => store.commit("resetSynvestFlow");

/*
*************** 
    SETTERS
***************
*/

export const setSynvestFlow = (flow: FlowName): void =>
  store.commit("setSynvestFlowName", flow);
export const setSynvestActivePage = (page: SynvestSteps): void =>
  store.commit("setSynvestActivePage", page);
export const setSynvestActiveSubstep = (substep: SynvestSubSteps): void =>
  store.commit("setSynvestActiveSubstep", substep);
export const setSynvestHasInvestment = (hasInvestment: boolean): void =>
  store.commit("setSynvestHasInvestment", hasInvestment);
export const setSynvestRequestType = (requestType: AanvragerType): void =>
  store.commit("setSynvestRequestType", requestType);
export const setSynvestInvestementAmount = (
  payload: SynvestInvestementAmount,
): void => {
  store.commit("setInvestementAmount", payload);
};
export const setCompanyInfo = (payload: CompanyInfo): void => {
  store.commit("setCompanyInfo", payload);
};
export const setCompanyContactPerson = (payload: ContactPerson): void => {
  store.commit("setCompanyContactPerson", payload);
};
export const setCompanyContactPersonTax = (payload: PersonTaxInfo): void => {
  store.commit("setCompanyContactPersonTax", payload);
};
export const setNavigateToPayment = (payload: boolean): void => {
  store.commit("setNavigateToPayment", payload);
};
export const setSynvestQuestionnaireScore = (score: number): void =>
  store.commit("setQuestionnaireScore", { score });
export const setSynvestQuestionnaireAnswers = (answers: Answer[]): void =>
  store.commit("setQuestionnaireAnswers", answers);
export const setSynvestQuestionnaireQuestions = (questions: Question[]): void =>
  store.commit("setSynvestQuestionnaireQuestions", questions);

/*
*************** 
    GETTERS
***************
*/

export const synvestInterfaceGetters: GetterTree<StoreState, StoreState> = {
  synvestFlow: (state) => state.synvestFlow,
  synvestFlowName: (state) => state.synvestFlow.currentFlow,
  synvestActivePage: (state) => state.synvestFlow.activePage,
  synvestActiveSubstep: (state) => state.synvestFlow.activeSubstep,
  synvestHasInvestment: (state) => state.synvestFlow.hasInvestment,
  synvestRequestType: (state) => state.synvestFlow.requestType,
  synvestInvestementAmount: (state) =>
    state.synvestFlow.synvestInvestementAmount,
  companyInfo: (state) => state.synvestFlow.companyInfo,
  navigateToPayment: (state) => state.synvestFlow.navigateToPayment,
  synvestQuestionnaireScore: (state) => state.synvestFlow.questionnaire?.score,
  synvestQuestionnaireAnswers: (state) => state.synvestFlow.questionnaire?.answers,
};

export const getSynvestFlow = (): SynvestFlowState => store.getters.synvestFlow;
export const getSynvestActivePage = (): SynvestSteps =>
  store.getters.synvestFlow.activePage;
export const getSynvestActiveSubstep = (): SynvestSubSteps | null =>
  store.getters.synvestFlow.activeSubstep;
export const getSynvestHasInvestment = (): boolean =>
  store.getters.synvestFlow.hasInvestment;
export const getSynvestRequestType = (): AanvragerType | null =>
  store.getters.synvestFlow.requestType;
export const getSynvestCurrentFlow = (): FlowName | null =>
  store.getters.synvestFlow.currentFlow;
export const getSynvestInvestementAmount = (): SynvestInvestementAmount => {
  return store.getters.synvestFlow.synvestInvestementAmount;
};
export const getCompanyInfo = (): CompanyInfo => store.getters.companyInfo;
export const getNavigateToPayment = (): boolean =>
  store.getters.navigateToPayment;
export const getSynvestQuestionnaireScore = () =>
  store.getters.synvestQuestionnaireScore;
export const getSynvestQuestionnaireAnswers = () =>
  store.getters.synvestQuestionnaireAnswers;

/*
*************** 
    GETTER ACTIONS
***************
*/

export async function getSynvestQuestionnaireQuestions() {
  const questions = store.state.synvestFlow.questionnaire?.questions;

  if (questions && questions.length > 0) {
    return questions;
  } else {
    showLoading(true);
    const [response] = await Promise.all([
      request(
        `/api/questionnaires/1`,
        requestMethodType.GET,
        {},
        {
          headers: {},
          auth: true,
        },
      ),
    ]);
    showLoading(false);

    const data: Questionnaire = response.data.data;
    setSynvestQuestionnaireQuestions(data.questions);
    return data.questions;
  }
}

export async function getSynvestQuestionnaireResults() {
  const answers = store.state.synvestFlow.questionnaire?.answers;
  if (answers && answers.every((answer) => answer.answer)) {
    const questions = await getSynvestQuestionnaireQuestions();

    const results = questions.map((question) => {
      const answerId = answers.find(
        (answer) => answer.question === question.id,
      )?.answer;

      return {
        Vraag: question.question,
        Antwoord: question.answers.find((answer) => answer.id === answerId)
          ?.answer,
      };
    });
    return results;
  } else {
    return null;
  }
}
