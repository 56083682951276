import { getUnpaidContractsCount } from '@/store/mainInterface';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

type Menu = {
  name: string;
  items: {
    name: string;
    route: string;
    active?: boolean;
    count?: number;
    href?: string;
  }[];
};

export default function useMenuItems() {
  const unpaidCount = computed(() => getUnpaidContractsCount());

  const menuItems = computed(() => {
    const currentRoute = useRoute()?.path;

    const items: Menu[] = [
      {
        name: 'Mijn overzicht',
        items: [
          {
            name: 'Mijn investeringen',
            route: '/mijn-investeringen',
          },
          {
            name: 'Betaling afronden',
            route: '/betaling-afronden',
            count: unpaidCount.value,
          },
          {
            name: 'Mijn berekeningen',
            route: '/mijn-berekeningen',
          }
        ]
      },
      {
        name: 'Producten toevoegen',
        items: [
          {
            name: 'Bedrag beleggen',
            route: '/bedrag-beleggen',
          },
          {
            name: 'Lijfrente opbouwen',
            route: '/lijfrente-opbouwen',
          },
          {
            name: 'Lijfrente uitkeren',
            route: '/lijfrente-uitkeren',
          },
        ]
      },
    ];

    items.forEach(menu => {
      menu.items.forEach(item => {
        item.active = currentRoute?.startsWith(item.route);
      });
    });

    return items;
  });

  return { menuItems };
}
