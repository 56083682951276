import { clearFileStore } from "@/db/files";
import router from "@/router";
import { resetAanvragerData } from "@/store/aanvragerInterface";
import { resetSynvestFlow, setSynvestFlow } from "@/store/synvestFlow";

export async function setFlowAndNavigate(path: string) {
  setSynvestFlow(path === "/nederlands-vastgoed" ? "nederlands" : "duits");
  await router.replace({ path, replace: true, query: { t: Math.random() } });
}

export function confirmExitFlow() {
  return window.confirm(
    "Weet u zeker dat u het maken van producten wilt afsluiten?",
  );
}

export async function navigateToFlow(path: string, activePage: string) {
  if (
    activePage !== "belegging-toevoegen" &&
    activePage !== "initial-payment" &&
    activePage !== "monthly-payment" &&
    confirmExitFlow()
  ) {
    /* Navigate and reset flow */
    resetSynvestFlow();
    resetAanvragerData();
    await clearFileStore();
    setFlowAndNavigate(path);
  } else {
    setFlowAndNavigate(path);
  }
}
