export enum SynvestSubSteps {
  UserAbroadTaxInfo = "UserAbroadTaxInfo",
  ChildAbroadTaxInfo = "ChildAbroadTaxInfo",
  PartnerAbroadTaxInfo = "PartnerAbroadTaxInfo",
  ChildTaxInfo = "ChildTaxInfo",
  PartnerTaxInfo = "PartnerTaxInfo",
  CompanyInformation = "CompanyInformation",
  ChildInformation = "ChildInformation",
  PartnerInformation = "PartnerInformation",
  UserInformation = "UserInformation",
  UserTaxInfo = "UserTaxInfo",
  ApplicantChoice = "ApplicantChoice",
}

export enum SynvestSteps {
  Investement = "belegging-toevoegen",
  Questionnaire = "questionnaire",
  Applicant = "applicant",
  ContractCreation = "contract-creation",
  InitialPayment = "initial-payment",
  MonthlyPayment = "monthly-payment",
}

interface Fond {
  ID: number;
  Naam: string;
  Percentage: number;
}

type Document = {
  Filename: string;
  Data: string;
};

type RelatieCommon = {
  SoortRelatieCode: string;
  BankRekeningNummer: string;
  BurgerServiceNummer: string;
  Email: string;
  GeboorteDatum?: string;
  DatumGeldigTot?: string;
  RsinNummer?: string;
  Kvknummer?: string;
  GeslachtCode: string;
  HuisNummer: string;
  LandCode: string;
  LegitimatieNummer: string;
  LegitimatieTypeCode: string;
  LegitimatiePerPost: boolean;
  Mobiel: string;
  Naam: string;
  NationaliteitCode?: string;
  PostCode: string;
  Straat: string;
  Telefoon: string;
  VoorLetters: string;
  TussenVoegsel: string;
  WoonPlaats: string;
  Documenten: Document[];
  BelastingPlichtingOESOLandCode?: string | null;
  TaxIdentificationNumber?: string | null;
  IsUSPersoon?: boolean | null;
};

// Type definition for the whole payload
export interface CreateContractPayload {
  Fondsen: Fond[];
  ProductCode: string;
  Ket?: {
    Vraag: string;
    Antwoord: string | undefined;
  }[];
  SoortDividendCode: "Cash" | "Stock";
  AanvangExtraStorting: number;
  PeriodiekeInleg: number;
  IsEersteBetalingIsZelfOvermaken: boolean;
  IngangsDatum: string;
  DoelBelegging: string;
  BepaaldeBeleggersProfielCode: string;
  GekozenBeleggersProfielCode: string;
  KeuzeRisicoprofielCode: string;
  EersteVerzekeringsnemerId?: number;
  TweedeVerzekeringnemerId?: number;
  TweedeVerzekeringnemer?: RelatieCommon;
  EersteVerzekeringnemer?: RelatieCommon;
  AantalVerzekeringsNemers?: number;
  IsHuidigeLijfrenteOversluiten: boolean;
  UitkeringsTermijnCode: string;
}
