<template>
 <button 
  :class="[env === 'synvest' ? 'rounded-3xl' : 'rounded-none', 'button', $attrs.class, props.type ?? 'primary']"
  :type="props.action ?? 'button'"
>
    <slot/>
  </button>
</template>

<script setup lang="ts">
import { currentEnv } from '@/plugins/variables';

const props = defineProps<{
  type?: 'primary' | 'secondary';
  action?: "button" | "submit" | "reset";
}>();

const env = currentEnv;
</script>

<style scoped lang="scss">
@import "@env_styles/variables";

.button {
  background: $button-primary-bg;
  padding: 8px 12px;
  color: $button-primary-text;
  border: 1px solid $button-primary-bg;
  transition: all 0.3s ease-out;
  //box-shadow: 0px 2px 4px rgba(6, 69, 106, 0.1), 0px 6px 2px rgba(6, 69, 106, 0.02);

  &.big {
    padding: 12px 16px;
  }

  &:hover {
    background: lighten($button-primary-bg-hover, 5%);
    color: $button-primary-text-hover;
    border-color: lighten($button-primary-bg-hover, 5%);
  }

  &:active {
    background: $button-primary-bg-down;
    color: $button-primary-text-down;
    border-color: $button-primary-bg-down;
  }

  &:disabled {
    background: $button-primary-bg-disabled;
    color: $button-primary-text-disabled;
    border-color: $button-primary-bg-disabled;
  }

  &.secondary {
    background: $button-secondary-bg;
    color: $button-secondary-text-down;
    border: 1px solid $button-secondary-text-down;

    &:hover {
      background: $button-secondary-bg-down;
      color: $button-secondary-text;
      border-color: $button-secondary-text;
    }

    &:active {
      background: $button-secondary-bg-down;
      color: $button-secondary-text-down;
      border-color: $button-secondary-text-down;
    }

    &:disabled {
      background: $button-secondary-bg-disabled;
      color: $button-secondary-text-disabled;
      border-color: $button-secondary-text-disabled;
    }
  }
}
</style>