<template>
  <CustomLoader :isLoading="showLoaderLogout" :text="loaderText"/>

  <Modal
      v-if="showModal"
      confirmButton="Oké"
      cancel-button="Annuleren"
      @confirm="logout"
      @close="showModal = !showModal"
  >
    <div class="text-center">Weet u zeker dat u wilt uitloggen?</div>
  </Modal>
  <div class="header">
    <nav
        class="flex items-center justify-between flex-wrap bg-label-color-4 px-0 md:px-6 h-[5rem] nav-synvest xl:rounded-b-3xl"
    >
      <!-- SynVest logo -->
      <div
          class="logo-top items-center flex-shrink-0 text-white mr-6 ml-2 lg:ml-12 md:ml-6"
      >
        <a href="https://synvest.nl/" target="_blank">
          <div class="w-[7rem] relative" />
          <img class="w-[7rem] sm:w-[10rem] lg:w-[11rem] logo-synvest" :src="synvestLogo" />
        </a>
      </div>
      <div class="flex items-center w-auto ml-auto mr-14">
        <div class="hidden md:flex flex-row items-center">
          <a
              href="https://synvest.nl/over-synvest/contact"
              target="_blank"
              :class="
              textSize + ' mt-0 text-white hover:text-white md:mr-8 lg:mr-14'
            "
          >
            Contact
          </a>

          <RouterLink
              v-if="isUserLoggedIn"
              to="/mijn-instellingen/persoonlijke-informatie"
              :class="textSize + ' mt-0 text-white hover:text-white lg:mr-14'"
          >
            {{ userName ?? user.email }}
          </RouterLink>
          <a
              @click="onLogoutModal"
              v-if="isUserLoggedIn"
              :class="
              textSize +
              ' cursor-pointer hidden lg:block  mt-0 text-white hover:text-white'
            "
          >
            Uitloggen
          </a>
          <RouterLink
              v-if="!isUserLoggedIn"
              to="/login"
              :class="textSize + ' mt-0 text-white hover:text-white lg:mr-14'"
          >
            Inloggen
          </RouterLink>
        </div>
      </div>
      <div v-if="isUserLoggedIn" class="block lg:hidden md:relative">
        <button
            class="text-white hidden md:block hover:text-white md:mr-7 mr-4"
            @click="showTabletMenu = !showTabletMenu"
        >
          <MenuIcon />
        </button>
        <div
            class="hidden p-8 mt-4 absolute bg-white right-6 w-72 border-2 border-label-color-5 z-50 rounded-3xl"
            :class="{ active: showTabletMenu }"
        >
          <div class="flex flex-col gap-3">
            <div
                v-for="(header, index) in menu"
                :key="index"
                class="border-b-[1px] pb-2"
            >
              <p :class="menuParentItem">
                {{ header.name }}
              </p>
              <ul>
                <li v-for="(title, index) in header.items" :key="index">
                  <div
                      v-if="title.route"
                      @click="
                      () => {
                        showTabletMenu = !showTabletMenu;
                        navigateToFlow(title.route, activePage);
                      }
                    "
                      :class="menuChildItem"
                      class="flex flex-row gap-3 items-center"
                  >
                    <span> {{ title.name }} </span>
                    <span v-if="title.count" class="font-semibold">
                      {{ title.count }}
                    </span>
                  </div>
                  <div :class="menuChildItem" v-if="title.href">
                    <a :href="title.href" target="_blank">
                      {{ title.name }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <a
                  href="https://synvest.nl/over-synvest/contact"
                  target="_blank"
                  :class="menuChildItem"
              >
                Contact
              </a>
              <a @click="onLogoutModal" :class="menuChildItem"> Uitloggen </a>
            </div>
          </div>
        </div>
        <button
            class="text-white md:hidden hover:text-white md:mr-11 mr-4"
            @click="showMobileMenu = !showMobileMenu"
        >
          <MenuIcon />
        </button>
        <div
            class="hidden p-8 mt-4 absolute bg-white right-6 w-72 border-2 border-label-color-5 z-50 rounded-3xl"
            :class="{ active: showMobileMenu }"
        >
          <div class="flex flex-col gap-3">
            <div
                v-for="(header, index) in menu"
                :key="index"
                class="border-b-[1px] pb-2"
            >
              <p :class="menuParentItem">
                {{ header.name }}
              </p>
              <ul>
                <li v-for="(title, index) in header.items" :key="index">
                  <div
                      v-if="title.route"
                      @click="
                      () => {
                        showMobileMenu = !showMobileMenu;
                        navigateToFlow(title.route, activePage);
                      }
                    "
                      :class="menuChildItem"
                      class="flex flex-row gap-3 items-center"
                  >
                    <span> {{ title.name }} </span>
                    <span v-if="title.count" class="font-semibold">
                      {{ title.count }}
                    </span>
                  </div>
                  <div :class="menuChildItem" v-if="title.href">
                    <a :href="title.href" target="_blank">
                      {{ title.name }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <a
                  href="/mijn-instellingen/persoonlijke-informatie"
                  :class="menuChildItem"
              >
                Mijn instellingen
              </a>
              <a
                  href="https://synvest.nl/over-synvest/contact"
                  target="_blank"
                  :class="menuChildItem"
              >
                Contact
              </a>
              <a @click="onLogoutModal" :class="menuChildItem"> Uitloggen </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, RouterLink } from "vue-router";
import synvestLogo from "@/assets/logo-synvest.svg";
import MenuIcon from "@/assets/MenuIcon.vue";
import { setClearAuth, setIsLogout } from "@/store/mainInterface";
import request, { requestMethodType } from "@/plugins/request";
import synMenuItems from "@/helpers/useSynvestMenu";
import { deleteDatabase } from "@/db";
import { navigateToFlow } from "@/helpers/navigationHelpers";
import { getSynvestActivePage } from "@/store/synvestFlow";
import Modal from "./Modal.vue";
import CustomLoader from "@/components/CustomLoader.vue";


const store = useStore();
const router = useRouter();

const showTabletMenu = ref(false);
const showMobileMenu = ref(false);
const showLoaderLogout = ref(false);
const loaderText = "";
const showModal = ref(false);
const activePage = computed(() => getSynvestActivePage());

const { synvestMenu } = synMenuItems();

const menu = synvestMenu;

const textSize = "text-base";

const menuParentItem = "font-black text-label-grey-3 pb-2 text-sm text-left";

const menuChildItem =
    "block pl-4 py-3 hover:border-l-4 border-label-color-2 text-label-grey-2 text-sm hover:text-label-color-2 hover:border-label-color-2 text-left font-medium";

const isUserLoggedIn = computed(() => store.getters.isAuthenticated);
const user = computed(() => store.getters.currentUser);
const userName = computed(() => store.getters.username);

const onLogoutModal = () => {
  showMobileMenu.value
      ? (showMobileMenu.value = false)
      : (showTabletMenu.value = false);
  showModal.value = !showModal.value;
};

const logout = async () => {
  /* Setting isLogout to bypass onBeforeRouteLeave fns
  in payment components */
  try {
    setIsLogout(true);
    setClearAuth();
    showModal.value = !showModal.value;
    showLoaderLogout.value = true

    // Validate API request
    await request(
        `/api/logout`,
        requestMethodType.POST,
        {},
        { headers: {}, auth: true },
    ).catch((error) => {
      console.error(`Error on request: ${error}`);
      throw error;
    });
    await request("/api/deleteCookie", requestMethodType.POST,
        {
          "cookieName": "token",
          "isHttpOnly": "true",
          "raw": "false",
          "sameSite": "Lax",
        });
    showLoaderLogout.value = false

    try {
      await deleteDatabase();
    } catch (error) {
      console.error(`Error on deleting database: ${error}`);
    }
    await router.push({ path: "/login" });
  } catch (error) {
    console.error(`Something went wrong on logout: ${error}`);
  }
};
</script>

<style scoped lang="scss">
@import "@env_styles/variables";

.relative {
  position: relative;
}

.logo-top {
  align-self: flex-start
}
.logo-synvest {
  position: absolute;
}

nav.nav-synvest {
  margin-bottom: 20px;
}

.active {
  display: block;
}
</style>
